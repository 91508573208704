import React from 'react'
import "./Widgets.css";
function Widgets() {
  return (
    <div className='widgets'>
       <div className="widgets__container">
       <img src=" https://images.unsplash.com/photo-1610116306796-6fea9f4fae38?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
       
       </div>
       <h3>Buy new books</h3>
    </div>
  )
}

export default Widgets